import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import DefaultLayout from '../layouts/default/defaultLayout'
import PropTypes from 'prop-types'
import { withIntl } from '../i18n'
import { injectIntl } from 'react-intl'
import TagsPage from '../layouts/tags/TagsPage'

class TagsIndex extends React.Component {
  static propTypes = {
    intl: PropTypes.object,
  }

  render() {
    const { intl } = this.props
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const tags = get(this, 'props.data.allMarkdownRemark.group')
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    // TODO: remove lang check when developing ENG version
    return (
      <DefaultLayout>
        <Helmet
          meta={[{ charset: 'utf-8' }]}
          title={this.props.intl.messages.tagsPage.title + ' | ' + siteTitle}
        />
        {intl.locale !== 'en' && <TagsPage tags={tags} posts={posts} intl={intl} />}
      </DefaultLayout>
    )
  }
}

export default withIntl(injectIntl(TagsIndex))

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD.MM.YYYY")
            tags
          }
        }
      }
    }
  }
`
