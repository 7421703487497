import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import style from './TagsPage.module.scss'
import kebabCase from 'lodash/kebabCase'

class TagsPage extends Component {
  static propTypes = {
    tags: PropTypes.array,
    posts: PropTypes.array,
    intl: PropTypes.object,
  }

  render() {
    const { intl } = this.props
    const posts = this.props.posts.filter(post => post.node.fields.slug.includes(`/posts_${intl.locale}`))
    let postsTags = []
    posts.forEach(post => {
      if (post.node.frontmatter.tags && post.node.frontmatter.tags[0]) {
        post.node.frontmatter.tags.forEach(tag => {
          if (!postsTags.includes(tag)) postsTags.push(tag)
        })
      }
    })
    const tags = this.props.tags.filter(tag => postsTags.includes(tag.fieldValue))
    return (
      <div className={style.tags}>
        <div className={style.innerContainer}>
          <h1>{intl.messages.tagsPage.title}</h1>
          <div className={style.tagsList}>
            {tags.map((tag, index) => {
              return (
                <Link key={index} to={`/${intl.locale}/tags/${kebabCase(tag.fieldValue)}/`}>{tag.fieldValue}</Link>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default TagsPage